<template>
  <div
    class="max-h-[100vh] h-[100vh] bg-gray-50 w-[100vw]"
    v-if="props.loading"
  >
    <div
      class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
    >
      <div class="loader"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  loading: Boolean,
});
</script>


<style scoped lang="scss">
.loader {
  width: 120px;
  height: 20px;
  -webkit-mask: linear-gradient(90deg, #000 70%, #0000 0) left/20% 100%;
  background: linear-gradient(theme("colors.primary") 0 0) left -25% top 0 /20%
    100% no-repeat #ddd;
  animation: l7 1s infinite steps(6);
}
@keyframes l7 {
  100% {
    background-position: right -25% top 0;
  }
}
</style>