import { createRouter, createWebHistory, createMemoryHistory, RouteRecordRaw } from 'vue-router';
import routes from './route'; // Ensure this is an array of routes

// Check if we're in the browser (SSR check)
const isBrowser = typeof window !== 'undefined';

// Create the history based on the environment
const history = isBrowser ? createWebHistory(import.meta.env.BASE_URL) : createMemoryHistory();

const router = createRouter({
  history,
  routes: routes as RouteRecordRaw[],
});
export default router;
