import posthog from 'posthog-js';

export default {
  install(app) {
    if (typeof window !== 'undefined') {
      posthog.init('phc_1Q0bGc9FU7jLAKpg6SLxe2KEYPxeFYtEmVy6BUxXqFV', {
        api_host: 'https://us.i.posthog.com',
      });

      app.config.globalProperties.$posthog = posthog;
    } else {
      console.warn('PostHog initialized in server-side context');
    }
  },
};
