import { RouteRecordRaw } from 'vue-router'

const routes:RouteRecordRaw[] = [
    {
        path: '/',
        component: () => import('../views/Home.vue'),
    },
    {
        path: '/career',
        component: () => import('../views/Career.vue'),
    },
    {
        path: '/faq',
        component: () => import('../views/Faqs.vue'),
    },
    {
        path: '/:catchAll(.*)*',
        name: '404',
        component: () => import('../views/404.vue'),
    },
]

export default routes
