<template>
  <div class="md:pt-[7rem] pt-[2rem] text-white bg-transparent" id="contact">
    <div class="flex overflow-hidden">
      <div class="wave"
        :style="{
          'background-image': 'url(' + wave + ')',
        }"
      ></div>
    </div>

    <div class="bg-[rgb(42_42_42)] pb-3">
      <div class="max-w-[1440px] mx-auto px-[10px]">
        <div class="md:py-[4rem] py-3 flex [flex-flow:_column] md:items-center">
          <p
            data-reveal="left"
            v-scroll-reveal
            class="lg:text-[72px] md:text-[62px] sm:text-[42px] text-[32px] font-bold relative leading-[1.1]"
          >
            Let us take care of
          </p>
          <p
            data-reveal="right"
            v-scroll-reveal
            class="lg:text-[72px] md:text-[62px] sm:text-[42px] text-[32px] font-bold relative sm:ml-[5rem] leading-[1.1]"
          >
            your software needs
          </p>
        </div>

        <div
          id="#contact"
          class="grid grid-cols-1 md:grid-cols-2 grid-row-[auto] w-full md:pb-[2.5rem] pb-[1rem] gap-4 items-end"
        >
          <iframe
            data-reveal
            v-scroll-reveal
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.2842606399427!2d85.31040887625572!3d27.677607876199673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19b47153008f%3A0xa68e5075b1ab1a17!2sDetech%20Solution%20Pvt%20Ltd!5e0!3m2!1sen!2snp!4v1731572265089!5m2!1sen!2snp"
            width="600"
            height="350"
            style="border: 0"
            loading="lazy"
            title="map"
            class="order-2 md:order-1 pt-3 w-full md:max-w-[600px] h-[350px] rounded-[12px]"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>

          <div
            class="md:order-2 flex flex-col gap-y-[1rem] md:gap-y-[4rem] gap-x-[4rem] pt-2 lg:py-0"
          >
            <div
              class="flex items-start justify-start flex-col gap-y-[1rem] gap-x-[1rem] overflow-hidden relative"
            >
              <p
                class="text-[1.125rem] leading-[1.3]"
                data-reveal="right"
                v-scroll-reveal
              >
                Have any questions? Reach out!<br />We reply fast
              </p>
              <a
                data-reveal="right"
                v-scroll-reveal
                href="mailto: contact@detech.com.np"
                class="text-white text-[1.25rem]"
              >
                contact@detech.com.np
              </a>
              <a data-reveal="right" v-scroll-reveal href="tel:+9779818000015"
                >+977 981-800-0015</a
              >
            </div>

            <div class="grid grid-cols-2 md:grid-cols-3 gap-[1rem]">
              <a
                v-for="(social, index) in socialData"
                :key="index"
                :href="social.link"
                target="_blank"
                data-reveal
                v-scroll-reveal
                class="social-link coolLink group last:col-span-2 md:last:col-span-1"
                :data-text="social.name"
              >
                <div
                  class="relative flex w-[2rem] h-[2rem] items-center justify-center"
                >
                  <div v-html="social.icons" class="relative z-10"></div>
                  <div
                    class="bg-gray-900 w-[2rem] h-[2rem] spin-animation absolute top-0 left-0"
                  ></div>
                </div>
                <p
                  class="relative grid body-1"
                  :data-text="social.name"
                >
                  <span class="font-bold">{{ social.name }}</span>
                </p>
              </a>
            </div>
          </div>
        </div>
        <div class="text-center py-5">
          <span class="text-primary pr-2">©</span
          >{{ new Date().getFullYear() }} Detech Solution Pvt Ltd. All rights
          reserved
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { facebook, instagram, linkdin } from "../icons/Icons";
import wave from "../assets/img/footer/wave.svg";

interface Social {
  name: string;
  link: string;
  icons: string;
}

const socialData: readonly Social[] = [
  {
    name: "Instagram",
    link: "https://www.instagram.com/detechsolution/?igshid=18nuf1w7ot442",
    icons: instagram,
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/detechsolution.io",
    icons: facebook,
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/company/detech-solution-np/",
    icons: linkdin,
  },
];
</script>

<style lang="scss" scoped>

.wave {
  width: 6400px;
  height: 55px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) alternate infinite;
  transform: translate3d(0, 0, 0);
}


@keyframes wave {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -1500px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

@keyframes spinBack {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.social-link {
  position: relative;
  display: flex;
  overflow: hidden;
  padding: 1rem;
  height: 12.5rem;
  align-items: start;
  justify-content: space-between;
  flex: 1;
  flex-flow: column;
  border-radius: 0.75rem;
  background: white;
  color: black;
  @media (max-width: 425px) {
    height: 10rem;
  }
  &:hover {
    .spin-animation {
      animation: spin 1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
    }
  }
}

.social-link:not(:hover) .spin-animation {
  animation: spinBack 1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

.coolLink {
  &::before {
    background-color: currentColor;
    transform: scale3d(0, 1, 1);
    transform-origin: right;
    transition: transform 0.4s cubic-bezier(0.5, 0.5, 0.3, 1);
  }
  &::after {
    content: attr(data-text);
    position: absolute;
    bottom: 18px;
    font-size: 18px;
    height: fit-content;
    font-weight: 700 !important;
    opacity: 0;
    transform: translate3d(150%, 0, 0);
    transition: transform 0.4s cubic-bezier(0.5, 0.5, 0.3, 1);
  }
  & span {
    transition: transform 0.4s cubic-bezier(0.5, 0.5, 0.3, 1);
  }
  &:hover::before {
    transform: scale3d(1, 1, 1);
    transform-origin: left;
  }
  &:hover::after {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  &:hover span {
    transform: translate3d(-150%, 0, 0);
  }
}
</style>
