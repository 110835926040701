import { DirectiveBinding } from 'vue';

interface HTMLElementWithScrollReveal extends HTMLElement {
  __scrollReveal__?: () => void;
}

export default {
  mounted(el: HTMLElementWithScrollReveal) {
    const revealElements = [el];
    const scrollReveal = () => {
      for (let i = 0; i < revealElements.length; i++) {
        const elementIsInScreen =
          revealElements[i].getBoundingClientRect().top <
          window.innerHeight / 1.15;
        if (elementIsInScreen) {
          revealElements[i].classList.add('revealed');
        } else {
          revealElements[i].classList.remove('revealed');
        }
      }
    };

    window.addEventListener('scroll', scrollReveal);
    scrollReveal();
    
    el.__scrollReveal__ = scrollReveal;
  },
  unmounted(el: HTMLElementWithScrollReveal) {
    if (el.__scrollReveal__) {
      window.removeEventListener('scroll', el.__scrollReveal__);
    }
  }
};
